import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AuthGet } from "../../common_var/httpService";

const ProfilePreview = () => {
  const navigate = useNavigate();
  const [userProfile, setUserProfile] = useState([]);
  const [showWaitingPic, setShowWaitingPic] = useState(false);
  const status = sessionStorage.getItem("status");

  const getUserProfile = async () => {
    let user_id = sessionStorage.getItem("userId");
    let res = await AuthGet(`customer/userprofile/${user_id}`, "borrower");
    if (res.statusCode === 200) {
      setUserProfile(res.data);
      sessionStorage.setItem("status", res?.data[0]?.customer_status);
      if (res.data[0].customer_status === "Verified") {
        navigate("/dashboard");
      }
    }
  };

  useEffect(() => {
    getUserProfile();
    if (status === "Basic Kyc Updated") {
      setShowWaitingPic(true);
    }
    window.history.pushState(null, null, window.location.href);
    window.onpopstate = function () {
      window.history.go(1);
    };
  }, [status]);

  const handleClick = () => {
    if (userProfile[0].customer_status === "Verified") {
      navigate("/dashboard");
    }
    setShowWaitingPic(true);
  };

  return (
    <div className="innermainsection height100vh">
      <div className="displayFlex AlignItemCenter  spacebetween mb40 ">
        <div></div>
        <div>
          <div className="titlename ">Hi {userProfile[0]?.firstname}</div>
          <div className="  brandsubtitle waitingColor  ">
            You are in waiting list
          </div>
        </div>
        <div onClick={() => navigate("/profile")} className="profileIcon">
          {userProfile[0]?.firstname[0]}
        </div>
      </div>

      {showWaitingPic && (
        <div className="waitinglistsection">
          <img src="/waitlist.png" />
          <div className="waitingtext">
            We will notify you as soon as you are eligible
          </div>
        </div>
      )}

      <div className="  displayFlex shopnowblock">
        <div className="circlebg"> </div>
        <div>
          <div className="fontWeight700">SMS Logs</div>
          <div className="fs10px">
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
          </div>
        </div>
      </div>
      <div className="  displayFlex shopnowblock">
        <div className="circlebg"> </div>
        <div>
          <div className="fontWeight700">SMS Logs</div>
          <div className="fs10px">
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
          </div>
        </div>
      </div>

      <div className="  displayFlex shopnowblock">
        <div className="circlebg"> </div>
        <div>
          <div className="fontWeight700">SMS Logs</div>
          <div className="fs10px">
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
          </div>
        </div>
      </div>
      <div className="  displayFlex shopnowblock">
        <div className="circlebg"> </div>
        <div>
          <div className="fontWeight700">SMS Logs</div>
          <div className="fs10px">
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
          </div>
        </div>
      </div>

      {!showWaitingPic && (
        <button
          onClick={handleClick}
          type="submit"
          className="blueButton mt100 mt30"
        >
          Allow access
        </button>
      )}
    </div>
  );
};

export default ProfilePreview;
