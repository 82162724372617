import { useFormik } from "formik";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Post } from "../../common_var/httpService";
import { customerInfoValidator } from "../../Components/FormikValidator/Validator";

const CustomerInfo = () => {
  const navigate = useNavigate();
  const { handleBlur, handleChange, handleSubmit, values, errors, touched } =
    useFormik({
      initialValues: {
        firstName: "",
        lastName: "",
        middle_initials: "",
        email: "",
        government_id: "",
        annual_income: "",
        country: "",
        customerId: "",
        date_of_birth: "",
      },
      validate: customerInfoValidator,
      onSubmit: async (values) => {
        try {
          values.customerId = sessionStorage.getItem("userId");
          values.annual_income = +values.annual_income;
          const resp = await Post(
            "users/updatepersonaldetails",
            values,
            "borrower"
          );
          if (resp.statusCode === 200) {
            navigate("/permission");
          } else if (resp.statusCode === 400) {
            alert("User Already Exists");
            console.log("rests",resp.message)
            navigate("/");
          }
        } catch (error) {
          console.log(error);
        }
      },
    });

    useEffect(() => {
      window.history.pushState(null, null, window.location.href);
      window.onpopstate = function () {
        window.history.go(1);
      };
    },[])

  return (
    <div className="innermainsection height100vh">
      {/* <img src="/back-icon.svg" /> */}

      <div className="theecodelogo mtmin20" >
        <img src="/theepay-logo.png" />
      </div>


      <div className="displayFlex AlignItemCenter justifyContent_center flex_column">
        <div className="titlename ">Tell us more about yourself</div>
        <div className="pb40 brandsubtitle">We’d love to get to know you</div>
      </div>

      <div>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label>Email Address</label>
            <input
              value={values.email}
              onChange={handleChange}
              onBlur={handleBlur}
              name="email"
              type="text"
              className={`form-control ${errors.email && touched.email && "redBorder"
                }`}
              placeholder="Enter Email Address"
            />
            {errors.email && touched.email ? (
              <span style={{ color: "red", fontSize: '12px' }}>{errors.email}</span>
            ) : (
              <></>
            )}
          </div>
          <div className="form-group">
            <label>First Name</label>
            <input
              value={values.firstName?.trim().replace(/[^a-zA-Z]+/g, '')}
              onChange={handleChange}
              onBlur={handleBlur}
              name="firstName"
              type="text"
              className={`form-control ${errors.firstName && touched.firstName && "redBorder"
                }`}
              placeholder="Enter First Name"
            />
            {errors.firstName && touched.firstName ? (
              <span style={{ color: "red", fontSize: '12px' }}>{errors.firstName}</span>
            ) : (
              <></>
            )}
          </div>
          <div className="form-group">
            <label>Middle Initial (Optional)</label>
            <input
              value={values.middle_initials?.trim().replace(/[^a-zA-Z]+/g, '')}
              onChange={handleChange}
              onBlur={handleBlur}
              name="middle_initials"
              type="text"
              className="form-control"
              placeholder="Enter Middle Initial"
            />
          </div>
          <div className="form-group">
            <label>Last Name</label>
            <input
              value={values.lastName?.trim().replace(/[^a-zA-Z]+/g, '')}
              onChange={handleChange}
              onBlur={handleBlur}
              name="lastName"
              type="text"
              className={`form-control ${errors.lastName && touched.lastName && "redBorder"
                }`}
              placeholder="Enter Last Name"
            />
            {errors.lastName && touched.lastName ? (
              <span style={{ color: "red", fontSize: '12px' }}>{errors.lastName}</span>
            ) : (
              <></>
            )}
          </div>

          <div className="form-group">
            <label>Date of Birth</label>
            <input
              value={values.date_of_birth}
              onChange={handleChange}
              onBlur={handleBlur}
              name="date_of_birth"
              type="date"
              className={`form-control ${errors.date_of_birth && touched.date_of_birth && "redBorder"
                }`}
            />
            {errors.date_of_birth && touched.date_of_birth ? (
              <span style={{ color: "red", fontSize: '12px' }}>{errors.date_of_birth}</span>
            ) : (
              <></>
            )}
          </div>
          <div className="form-group">
            <label>Government ID Number</label>
            <input
              value={values.government_id?.trim().replace(/[^\w\s\][^,]/gi, '')}
              onChange={handleChange}
              // maxLen={11}
              maxlength="14"
              onBlur={handleBlur}
              name="government_id"
              type="text"
              className={`form-control ${errors.government_id && touched.government_id && "redBorder"
                }`}
              placeholder="Government ID Number"
            />
            {errors.government_id && touched.government_id ? (
              <span style={{ color: "red", fontSize: '12px' }}>{errors.government_id}</span>
            ) : (
              <></>
            )}
          </div>
          <div className="form-group pb50">
            <label>Annual Income (Optional) </label>
            <input
              value={values?.annual_income?.replace(/[^0-9\\.]+/g, '')}
              onChange={handleChange}
              onBlur={handleBlur}
              name="annual_income"
              type="text"
              className="form-control pl60"
              placeholder="Annual Income  "
            />
            <div className="aedtext">AED  </div>
          </div>



          <button type="submit" className="blueButton">
            Continue
          </button>
        </form>
      </div>
    </div>
  );
};

export default CustomerInfo;
