import React from "react";
import Navbar from "../../Components/Navbar/Navbar";

const Card = () => {
  return (
    <>
      <div className="walletpage">
        <div className="headersection">
          <div>
            {" "}
            <img src="/back-icon.svg" />{" "}
          </div>
          <div>Wallet</div>
          <div></div>
        </div>

        <div className="contentSection">
          <div className="whiteBox2">
            <div>Available Balance</div>
            <div className="fontWeight700">0000.00</div>
          </div>

          <div className="theepaycardsection">
            <div className="theepaycard">
              <div className="cardblock">
                <div className="cardNumber">2030 XXXX XXXX 7243</div>

                <div className="carddate">
                  <div>
                    <div>Member Since</div>
                    <div>12/14</div>
                  </div>
                  <div>
                    <div>Valid Till</div>
                    <div>09/24</div>
                  </div>
                </div>

                <div className="cardname"> Balamurugan k</div>
              </div>
            </div>
            <div className="viewcardtext">Tab to view card details</div>
          </div>

          <div className="howitwork">
            <div className="maintitle">How it works :</div>
            <div className="listsection">
              <div className="innercircle">1</div>
              <div>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry.
              </div>
            </div>
            <div className="listsection">
              <div className="innercircle">2</div>
              <div>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry.
              </div>
            </div>
            <div className="listsection">
              <div className="innercircle">3</div>
              <div>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry.
              </div>
            </div>
            <div className="listsection">
              <div className="innercircle">4</div>
              <div>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry.
              </div>
            </div>
          </div>

          <button className="blueButton mt100 mt30">Create Card</button>
        </div>
      </div>
      <Navbar />
    </>
  );
};

export default Card;
