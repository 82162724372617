import React from "react";
import { useFormik } from "formik";
import { useStateValue } from "../../Context/StateProvider";
import { useNavigate } from "react-router-dom";
import { Post } from "../../common_var/httpService";
import { DialCodes } from "../../Components/DialCodes";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";

const PhoneNumber = () => {
  const [{}, dispatch] = useStateValue();
  const navigate = useNavigate();
  const { handleChange, handleSubmit, handleBlur, values, errors, touched } =
    useFormik({
      initialValues: {
        phone: "",
      },
      validate: (values) => {
        const errors = {};
        if (values.phone?.trim()) {
          if (!isValidPhoneNumber(values.phone ? values.phone : "")) {
            errors.phone = "Invalid phone number";
          } else if (!values.phone?.trim()) {
            errors.phone = "Required";
          }
        }
        return errors;
      },
      onSubmit: async (values) => {
        try {
          dispatch({
            type: "SET_PHONENUMBER",
            payload: values,
          });
          sessionStorage.setItem("phoneNumber", values.phone);
          let body = { phone: values?.phone?.slice(1, values?.phone?.length) };
          let resp = await Post("users/signin", body, "borrower");
          if (resp.statusCode === 200) {
            navigate("/verifyotp");
          }
        } catch (error) {
          console.log(error);
        }
      },
    });

  const Change = (dial_code) => {
    sessionStorage.setItem("dialCode", dial_code);
    values.mobile_code = dial_code.slice(1);
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="mainsection height100vh">
        <div className="displayFlex flex_column spacebetween height100per">
          <div className="">
            <div className="displayFlex AlignItemCenter justifyContent_center flex_column">
              <div className="theecodelogo">
                <img src="/theepay-logo.png" />
              </div>

              <div className="brandName pt_40 ">Credit BNPL App</div>
              <div className="pb40 brandsubtitle">
                Pay later for everything, everywhere
              </div>
            </div>
            <div className="mob_no">
              <label className="pb_5">Mobile Number</label>

              <div className="displayFlex gap_10">
                {/* <div>
                <select
                  className={`form-select w100px height45 ${
                    errors.mobile_code && touched.mobile_code && "redBorder"
                  }`}
                  name="mobile_code"
                  onChange={(e) => Change(e.target.value)}
                  onBlur={handleBlur}
                >
                  <option value="">Select Code </option>
                  {DialCodes?.map((code, i) => {
                    return (
                      code.active_flag === "Y" && (
                        <option key={i} value={code.dial_code}>
                          {`${code.dial_code} (${code.code})`} 
                        </option>
                      )
                    );
                  })}
                </select>
                {errors.mobile_code && touched.mobile_code ? (
                  <span className="RequireText">{errors.mobile_code}</span>
                ) : (
                  <></>
                )}

                </div> */}
                <div className="width_100p">
                  <PhoneInput
                    style={
                      errors.phone && touched.phone
                        ? { border: "1px solid red" }
                        : {}
                    }
                    className="mobileInput"
                    defaultCountry="US"
                    international
                    type="text"
                    formControlName="mobileNumber"
                    id="mobile"
                    // mask="000-00-0000"
                    placeholder="Phone Number"
                    onChange={(e) => values.phone = e}
                    name="phone"
                    onBlur={handleBlur}
                    value={values?.phone}
                  />
                  {/* <input
                  maxLength={10}
                  name="phone"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.phone?.replace(/[^\d]/g, "")}
                  type="text"
                  className={`form-control ${
                    errors.phone && touched.phone && "redBorder"
                  }`}
                  placeholder="Enter mobile number"
                /> */}
                  {errors.phone && touched.phone ? (
                    <span className="RequireText">{errors.phone}</span>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
            {
              <div className="checkboxtext dflex align_center mt12">
                <input type="checkbox" id="agreeWhatsapp" />
                <label className="pl_5 dflex" style={{marginTop: '2px'}} htmlFor="agreeWhatsapp">
                  Send me remainders and alerts on{" "}
                  <img
                    className="p_lr_5"
                    src="/whatsapp_icon.svg"
                    alt="WhatsappIcon"
                  />{" "}
                  Whatsapp
                </label>
              </div>
            }
          </div>

          <div>
            <p className="fs12px By_continuing">
              By continuing, I agree to{" "}
              <a className="col_var_blue" href="#">
                Privacy Policy,{" "}
              </a>
              <a className="col_var_blue" href="#">
                T&C and Bank Credit information
              </a>
            </p>
            <button type="submit" className="blueButton mt_20">
              Get Started
            </button>
          </div>
        </div>
      </div>
    </form>
  );
};

export default PhoneNumber;
