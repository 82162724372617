import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Navbar from "../../Components/Navbar/Navbar";
import { AuthGet, Get } from "../../common_var/httpService";

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";

const Dashboard = () => {
  const [user, setUser] = useState({});
  const navigate = useNavigate();
  const user_id = sessionStorage.getItem("userId");
  const token = sessionStorage.getItem("borrower_token");

  const [transactions, setTransactions] = useState([]);

  const getAllTransactions = async () => {
    try {
      const resp = await AuthGet(
        `transaction/get_transcation/${user_id}`,
        "borrower"
      );
      if (resp.statusCode === 200) {
        setTransactions(resp.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getUserDetails = async () => {
    try {
      const resp = await AuthGet(
        `transaction/getdashboarddetails/${user_id}`,
        "borrower"
      );
      setUser(resp.data[0]);
      sessionStorage.setItem("available_balance", resp.data[0].wallet_balance);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllTransactions();
    getUserDetails();
  }, []);

  const progressValue = Math.round(
    100 -
      ((user.wallet_creditlimit - user.wallet_balance) * 100) /
        user.wallet_creditlimit
  );

  return (
    <>
      <div className="dasboardpage">
        <div>
          <div className="dashboardHeader">
            <div className="displayFlex spacebetween width100per pb20">
              <div className="displayFlex">
                <div
                  className="profileImage_dashboard"
                  onClick={() => navigate("/profile")}
                >
                  {user.firstname ? user.firstname[0] : ""}
                </div>
                <div>
                  <div className="graytext">hello</div>
                  <div className="profilename">{`${
                    user.firstname ? user.firstname : ""
                  } ${user.lastname ? user.lastname : ""}`}</div>
                </div>
              </div>

              <div>
                <div className="graytext">Available Limit</div>
                <div className="profilename">AED {user.wallet_balance}</div>
              </div>
            </div>

            <Carousel
              infiniteLoop
              useKeyboardArrows
              showThumbs={false}
              showStatus={false}
              showArrows={false}
              showIndicators={false}
            >
              <div>
                <div className="dasboardbox">
                  <div className="displayFlex spacebetween pb20">
                    <div>
                      <div className="amountduetxt"> Amount Due</div>

                      <button
                        onClick={() => navigate("/wallet")}
                        className={`paynowButton ${
                          user.wallet_balance === user.wallet_creditlimit
                            ? "opactiyddull_5"
                            : ""
                        }`}
                        disabled={
                          user.wallet_balance === user.wallet_creditlimit
                        }
                      >
                        {" "}
                        Pay Now
                      </button>
                    </div>
                    <div>
                      <div className="amountduevalue">
                        AED{" "}
                        
                          { user.wallet_spend}
                      </div>
                      {/* <div className="duetext"> Due by February 05</div> */}
                    </div>
                  </div>
                  <div>
                    <div className="pb10">
                      <div className="progressbar"></div>
                      <div
                        className="progressbarheader"
                        style={{ width: `${user.progress_value}%` }}
                      ></div>
                    </div>

                    <div className="displayFlex spacebetween">
                      <div className="colorwhite fs10px">
                        <span className="fontWeight700">
                          AED {user.wallet_spend}
                        </span>
                        <span> Spends</span>
                      </div>
                      <div className="colorwhite fs10px">
                        <span className="fontWeight700">
                          AED {user.wallet_creditlimit}
                        </span>
                        <span> Limit</span>
                      </div>
                    </div>
                  </div>
                  <div></div>
                </div>
              </div>
              <div>
                <div className="theepaycard opactiyddull_5">
                  <div className="cardblock">
                    <div className="cardNumber">2030 XXXX XXXX 7243</div>

                    <div className="carddate">
                      <div>
                        <div>Member Since</div>
                        <div>12/14</div>
                      </div>
                      <div>
                        <div>Valid Till</div>
                        <div>09/24</div>
                      </div>
                    </div>

                    <div className="cardname"> Card Holder Name</div>
                  </div>
                </div>
              </div>
            </Carousel>
          </div>

          <div className="whiteBox">
            <div className="title">You can also use Theepay on</div>

            <div className="productBlock">
              <div onClick={() => navigate("/shop")}>
                <img src="/movie.png" />

                <div className="producttext">Movie</div>
              </div>
              <div className="opacity_3">
                <img src="/shopgb.png" />

                <div className="producttext">Shop GB</div>
              </div>
              <div className="opacity_3">
                <img src="/ebay.png" />

                <div className="producttext">ebay</div>
              </div>

              <div className="opacity_3">
                <img src="/jio.png" />

                <div className="producttext">Jio</div>
              </div>

              <div className="opacity_3">
                <img src="/bestshop.png" />

                <div className="producttext">Best Shop</div>
              </div>
            </div>
          </div>
          <div className="whiteBox">
            <div className="title">Recent Transactions</div>

            {transactions.length > 0 ? (
              <div className="recentTransactionsection">
                {transactions?.map((upcoming, i) =>
                  i < 3 ? (
                    <div key={i} className="recentTransactionsblock">
                      <div className="displayFlex">
                        <div className="icon">
                          <img width={"75%"} height={"75%"} src="/movie.png" />
                        </div>

                        <div>
                          <div className="innertitle">
                            {upcoming.product_name}
                          </div>
                          <div className="date">{upcoming.to_char}</div>
                        </div>
                      </div>
                      <div className="value">
                        {" "}
                        {upcoming.transactionType == "Purchased" ? "-" : "+"}
                        {upcoming.amount} AED
                      </div>
                    </div>
                  ) : (
                    <></>
                  )
                )}
              </div>
            ) : (
              <p style={{ textAlign: "center", fontSize: "12px" }}>
                No Transactions Available
              </p>
            )}
          </div>
        </div>
      </div>
      <Navbar />
    </>
  );
};

export default Dashboard;
