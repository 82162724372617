import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AuthGet } from "../../common_var/httpService";
import Navbar from "../../Components/Navbar/Navbar";

const Wallet = () => {
  const navigate = useNavigate();
  const [upcomingPayments, setUpcomingPayments] = useState([]);
  const [userProfile, setUserProfile] = useState([]);
  const user_id = sessionStorage.getItem("userId");

  console.log(upcomingPayments);
  const getUpcomingPayment = async () => {
    try {
      const resp = await AuthGet(
        `transaction/get_purchase/${user_id}`,
        "borrower"
      );
      if (resp.statusCode === 200) {
        setUpcomingPayments(resp.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getUserProfile = async () => {
    let user_id = sessionStorage.getItem("userId");
    let res = await AuthGet(`customer/userprofile/${user_id}`, "borrower");
    if (res.statusCode === 200) {
      setUserProfile(res.data[0]);
    }
  };
  useEffect(() => {
    getUpcomingPayment();
    getUserProfile();
  }, []);

  return (
    <div>
      <div className="headersection">
        <div onClick={() => navigate("/dashboard")}>
          {" "}
          <img src="/back-icon.svg" />{" "}
        </div>
        <div>Wallet</div>
        <div></div>
      </div>

      <div className="contentSection plastic_cloudbg">
        <div className="whiteBox2 blancetxt">
          <div>Available Balance</div>
          <div className="fontWeight700">AED {userProfile?.wallet_balance}</div>
        </div>

        <div className="theepaycardsection ">
          <div className="theepaycard">
            <div className="cardblock">
              <div className="cardNumber">2030 XXXX XXXX 7243</div>

              <div className="carddate">
                <div>
                  <div>Member Since</div>
                  <div>12/14</div>
                </div>
                <div>
                  <div>Valid Till</div>
                  <div>09/24</div>
                </div>
              </div>

              <div className="cardname"> Card Holder Name</div>
            </div>
          </div>
          <div className="viewcardtext colorgrey mt_10 ">Tab to view card details</div>
        </div>
      </div>
      <div>
        <div className="upcomingpaymentsblock displayFlex spacebetween AlignItemCenter">
          <div className="upcomingpaytitle">Upcoming payments</div>
          <div>
            <img src="/wallet-dropuparrow.svg" alt="img" />
          </div>
        </div>
        {
          <div>
            {upcomingPayments.length === 0 ? (
              <div
                style={{ textAlign: "center", fontSize: "12px", marginTop: 50 }}
              >
                No Outstanding Payments 😊
              </div>
            ) : (
              upcomingPayments?.map((upcoming, i) => (
                <div
                  className="displayFlex AlignItemCenter spacebetween simplebillcard"
                  key={i}
                >
                  <div className="displayFlex AlignItemCenter ">
                    <div>
                      <img
                        width={"75%"}
                        height={"75%"}
                        src="/movie.png"
                        alt="img"
                      />
                    </div>
                    <div className="simplebilltext">
                      <div className="simplebilltitle">
                        {upcoming.product_name}
                      </div>
                      <p className="simplebillsubtitle">{upcoming.to_char}</p>
                    </div>
                  </div>
                  <div>
                    <button
                      className="walletpaybtn"
                      onClick={() =>
                        navigate(`/wallet-details/${upcoming.loan_id}`)
                      }
                    >
                      Pay AED {upcoming.amount}
                    </button>
                  </div>
                </div>
              ))
            )}
          </div>
        }
      </div>
      <Navbar />
    </div>
  );
};

export default Wallet;
