import { useFormik } from "formik";
import React from "react";
import { useNavigate } from "react-router-dom";
import Navbar from "../../Components/Navbar/Navbar";

const SplitPay = () => {
  const navigate = useNavigate();
  const Today = new Date();
  let schedule = [];
  let terms = 3;
  let principal = sessionStorage.getItem("principal");
  let amount = principal / terms;

  let getMonth = Today.getMonth();

  for (let i = 0; i < terms; i++) {
    let paymentDate = new Date(Today.setMonth(getMonth + i + 1));
    schedule.push({
      date: `${
        paymentDate.getDate() < 10
          ? `0${paymentDate.getDate()}`
          : paymentDate.getDate()
      } ${paymentDate.toLocaleString("default", {
        month: "short",
      })} ${paymentDate.getFullYear()}`,
      amount,
    });
  }

  const { handleBlur, handleSubmit, values, errors, touched } = useFormik({
    initialValues: {
      payment_mode: "",
    },
    validate: (values) => {
      const errors = {};

      if (!values.payment_mode) {
        errors.payment_mode = "Required";
      }

      return errors;
    },
    onSubmit: (values) => {
      try {
        sessionStorage.setItem("pay_mode", values.payment_mode);
        navigate("/pay");
      } catch (error) {
        console.log(error);
      }
    },
  });

  const handleChange = (e) => {
    values.payment_mode = e.target.value;
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className="walletpage">
          <div className="headersection">
            <div onClick={() => window.history.back()}>
              <img src="/back-icon.svg" />
            </div>
            <div>Payments</div>
            <div></div>
          </div>

          <div className="contentSection">
            <div className="innertitle">Payments plan</div>

            <div
              className="whiteBox3"
              style={
                errors.payment_mode &&
                touched.payment_mode && { border: "1px solid red" }
              }
            >
              <label htmlFor="pay-later" style={{ width: "100%" }}>
                <div className="displayFlex">
                  <input
                    id="pay-later"
                    type="radio"
                    name="payment_mode"
                    className="mr20"
                    onChange={(e) => handleChange(e)}
                    onBlur={handleBlur}
                    value="Pay Later"
                  />
                  Pay Later
                </div>
              </label>
            </div>

            <div
              className="whiteBox3 flex_column"
              style={
                errors.payment_mode &&
                touched.payment_mode && { border: "1px solid red" }
              }
            >
              <label htmlFor="split-pay">
                <div className="displayFlex">
                  <input
                    type="radio"
                    name="payment_mode"
                    id="split-pay"
                    className="mr20"
                    onChange={(e) => handleChange(e)}
                    onBlur={handleBlur}
                    value="Pay in"
                  />
                  Split Pay
                </div>

                <div className="splitpayblock">
                  {schedule?.map((sched, i) => {
                    return (
                      <div className="displayFlex spacebetween" key={i}>
                        <div className="displayFlex">
                          <div>
                            <div className="splitcircle"></div>
                            <div
                              className={
                                schedule.length - 1 === i ? "" : "splitline"
                              }
                            ></div>
                          </div>

                          <div className="splitDate">{sched.date}</div>
                        </div>

                        <div>AED {sched.amount}</div>
                      </div>
                    );
                  })}
                </div>
              </label>
            </div>

            <div className="innertitle">Payment Method </div>

            <div className="whiteBox3">
              <input type="text" placeholder="Promo Code" />
              <button type="button">Apply</button>
            </div>

            <div className="whiteBox3">
              <div>
                <div className="totalamouttxt">Total Amount</div>
                <div className="totalvaluetxt">AED 1386.56</div>
              </div>

              <button type="submit" className="blueButton winherit">
                Pay Now
              </button>
            </div>
          </div>
        </div>

        <div>
          <div></div>

          {/* <div>
            <h4>Payment Method</h4>
            <input type="text" placeholder="Promo Code" />
            <button>Apply</button>
          </div> */}

          {/* <div>
            <p>Total Amount</p>
            <p>AED 1400</p>
            <button className="blueButton" onClick={() => navigate("/pay")}>
              Pay Now
            </button>
          </div> */}
        </div>
      </form>
      <Navbar />
    </>
  );
};

export default SplitPay;
