import { useFormik } from "formik";
import React, { useEffect, useState , useRef} from "react";
import { useStateValue } from "../../Context/StateProvider";
import { HStack, PinInput, PinInputField, FormControl } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { Post } from "../../common_var/httpService";
// import { toast } from 'react-hot-toast';
// import Toast from '../../Components/Toaster/Toast';

const OtpPage = () => {
  const [timer, setTimer] = useState(120);
  const navigate = useNavigate();
  const [{ phoneNumber }, dispatch] = useStateValue();
  // const dail_code = sessionStorage.getItem("dialCode");
  const ph = sessionStorage.getItem("phoneNumber");
  useEffect(() => {
    if (ph == null) {
      navigate("/");
    }
  }, []);

  const {
    handleChange,
    handleSubmit,
    handleBlur,
    values,
    errors,
    touched,
    setValues,
  } = useFormik({
    initialValues: {
      otp: "",
    },
    validate: (values) => {
      const errors = {};

      if (values.otp.replace(/\D/g, "").length !== 6) {
        errors.otp = "Invalid OTP";
      }

      return errors;
    },
    onSubmit: async (values) => {
      try {
        const body = {
          phone: ph?.slice(1,ph?.length),
          otp: values.otp,
        };
        const resp = await Post("users/verify_otp", body, "borrower");
        if (resp.statusCode === 200) {
          sessionStorage.setItem("borrower_token", resp?.data.jwtaccesstoken);
          sessionStorage.setItem("userId", resp?.data.customerId);
          sessionStorage.setItem("refreshToken", resp?.data.refreshtoken);
          sessionStorage.setItem("status", resp?.data.status);

          if (resp?.data.status == "Basic Kyc Updated") {
            navigate("/profilepreview");
          } else if (resp?.data.status == "Pending") {
            navigate("/customerinfo");
          } else {
            navigate("/dashboard");
          }
        }
        else if (resp.statusCode === 400) {
          alert(resp.message[0]);
        } else {

          alert("Invalid Otp");
          // resetForm()
        }
      
      } catch (error) {
        console.log(error);
        
      }
    },
  });

  useEffect(() => {
    timer > 0 &&
      setTimeout(() => {
        setTimer(timer - 1);
      }, 1000);
  }, [timer]);

  const handleOTP = (otp) => {
    let val = { otp: otp };
    setValues(val);
  };

  const handleResendOTP = async ({resetForm}) => {
    // this.myFormRef.reset();
    try {
      const body = {
        // mobile_code: dail_code.slice(1),
        phone: ph?.slice(1,ph?.length),
      };
      let resp = await Post("users/resendotp", body, "borrower");
      if (resp.statusCode === 200) {
        setValues({ otp: '' })
        setTimer(120);
      }
      else{
        alert("ttrsrtstr")
        resetForm();
        
      }
    } catch (error) {

      console.log(error);
    }
  };

  return (
    <form id="otpReset" onSubmit={handleSubmit} >
      <div className="otpbg">
        <div className="whiteBg">
          <div className="otptitle">Enter 6 digit OTP</div>
          <div className="otpsubtitle">
            We just sent a OTP to your mobile number
          </div>
          <div className="otpnumbertxt">
            {ph}
          </div>

          <FormControl>
            <HStack>
              <PinInput value={values.otp}
                otp
                autoFocus
                placeholder="-"
                onChange={(e) => handleOTP(e)}
              >
                <PinInputField />
                <PinInputField />
                <PinInputField />
                <PinInputField />
                <PinInputField />
                <PinInputField />
              </PinInput>

              {errors.otp ? <p className="invalidopt">{errors.otp}</p> : <></>}
            </HStack>
          </FormControl>
          {timer != 0 && (
            <p style={{ paddingTop: "20px" }}>Waiting for Resend OTP... {timer}s</p>
          )}
          {timer == 0 && (
            <button
              type="button"
              onClick={handleResendOTP}
              className="resendotpbutton"
            >
              <div className="pr15"> Resend OTP</div>
              <img src="/arrow.png" />{" "}
            </button>
          )}
        </div>
        <button type="submit" className="blueButton">
          Continue
        </button>
      </div>
    </form>
  );
};

export default OtpPage;
