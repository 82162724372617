import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AuthGet, AuthPost } from "../../common_var/httpService";
import Navbar from "../../Components/Navbar/Navbar";

const Transactions = () => {
  const [transactions, setTransactions] = useState([]);
  const user_id = sessionStorage.getItem("userId");
  const navigate = useNavigate();

  const getAllTransactions = async () => {
    try {
      const resp = await AuthGet(
        `transaction/get_transcation/${user_id}`,
        "borrower"
      );
      if (resp.statusCode === 200) {
        setTransactions(resp.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllTransactions();
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="walletpage">
        <div className="headersection">
          <div onClick={() => navigate("/dashboard")}>
            {" "}
            <img src="/back-icon.svg" />{" "}
          </div>
          <div>Transactions</div>
          <div></div>
        </div>

        <div className="contentSection">
          <div className="whiteBox">
            {transactions.length > 0 ? (
              transactions?.map((trans, i) => (
                <div key={i} className="recentTransactionsblock">
                  <div className="displayFlex">
                    <div className="icon">
                      <img width={"75%"} height={"75%"} src="/movie.png" />
                    </div>

                    <div>
                      <div className="innertitle">{trans.product_name}</div>
                      <div className="date">{trans.to_char}</div>
                    </div>
                  </div>
                  <div className="value">
                    {trans.transactionType == "Purchased" ? "-" : "+"}
                    {trans.amount} AED
                  </div>
                </div>
              ))
            ) : (
              <p style={{ textAlign: "center", fontSize: "12px" }}>
                No Transactions Available
              </p>
            )}
          </div>
        </div>
      </div>
      <Navbar />
    </>
  );
};

export default Transactions;
