export const initialState = {
  phoneNumber: "",
};

export const reducer = (state, action) => {
  switch (action.type) {
    case "SET_PHONENUMBER":
      return {
        ...state,
        phoneNumber: action.payload.phone,
      };

    default:
      return state;
  }
};
