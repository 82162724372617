import React from "react";
import { useNavigate } from "react-router-dom";
import Navbar from "../../Components/Navbar/Navbar";

const PaymentOptions = () => {
  const navigate = useNavigate();
  const handleClick = () => {
    sessionStorage.setItem("principal", 1381.56);
    navigate("/pay-method");
  };
  return (
    <>
      <div className="walletpage">
        <div className="headersection">
          <div onClick={() => window.history.back()}>
            <img src="/back-icon.svg" />
          </div>
          <div>Movie Ticket</div>
          <div></div>
        </div>
        <div className="contentSection">
          <img src="/choosepayment.png" />

          <div>
            <button className="blueButton mt100" onClick={handleClick}>
              Pay 1381.56 AED
            </button>
          </div>
        </div>
      </div>
      <Navbar />
    </>
  );
};

export default PaymentOptions;
