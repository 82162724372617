import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const PaymentSuccess = () => {
  const navigate = useNavigate();
  useEffect(() => {
    setTimeout(() => {
      navigate("/dashboard");
    }, 3000);
  });
  return <div>


    <>

      <div className="successpage">

        <img src="/payment-success.png" />
        <div className="textAlign_center">
          <div className="successtext"> Thank You</div>
          <div className=" "> Payment Done Successfully</div>
        </div>


      </div>

    </>


  </div>;
};

export default PaymentSuccess;
