import React from "react";
import { useNavigate } from "react-router-dom";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";

const Splash = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className="splashscreen">
        <Carousel
          infiniteLoop
          useKeyboardArrows
          showThumbs={false}
          showStatus={false}
          showArrows={false}
        >
          <div>
            <img src="/splash-01.png" className="splashimg" />
            <p className="splashtitle">Instant Payments, ZERO interest</p>

            <p className="splashcontent">Payback after 15 days</p>
          </div>
          <div>
            <img src="/splash-02.png" className="splashimg" />
            <p className="splashtitle widthinherit">
              Pay later at all your favorite merchants
            </p>

            <p className="splashcontent">
              Pay from credit not from bank account
            </p>
          </div>
        </Carousel>

        <div className="skiptext" onClick={() => navigate('/home')}> Skip </div>
      </div>
    </>
  );
};

export default Splash;
