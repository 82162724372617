import { EmailRegex } from "../Regex/Regex";

const today = new Date();
let maxDate = new Date(
  today.getFullYear() - 18,
  today.getMonth(),
  today.getDate()
);

export const customerInfoValidator = (values) => {
  const errors = {};

  if (!values.email.trim()) {
    errors.email = "Required";
  } else if (!EmailRegex.test(values.email.trim())) {
    errors.email = "Invalid Email";
  }

  if (!values.firstName.trim()) {
    errors.firstName = "Required";
  }

  if (!values.lastName.trim()) {
    errors.lastName = "Required";
  }

  if (!values.date_of_birth) {
    errors.date_of_birth = "Required";
  } else if (new Date(values.date_of_birth) > maxDate) {
    errors.date_of_birth = "Age should be greater than 18";
  }

  if (!values.government_id.trim()) {
    errors.government_id = "Required";
  } else if (values.government_id.trim().length < 14) {
    errors.government_id = "Government id  should be 14 characters"
    
  } 

  return errors;
};
