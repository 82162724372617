import React from "react";
import { useNavigate } from "react-router-dom";
import Navbar from "../../Components/Navbar/Navbar";

const Shop = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className="walletpage">
        <div className="headersection">
          <div onClick={() => navigate('/dashboard')}>
            <img src="/back-icon.svg" />
          </div>
          <div>Movie Ticket</div>
          <div></div>
        </div>
        <div className="contentSection">
          <img src="/shop.png" />

          <div>
            <button
              className="blueButton mt100"
              onClick={() => navigate("/select")}
            >
              Book Tickets
            </button>
          </div>
        </div>
      </div>
      <Navbar />
    </>
  );
};

export default Shop;
