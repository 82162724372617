import { useRoutes } from "react-router-dom";
import CustomerInfo from "./Onboarding/Pages/CustomerInfo";
import Dashboard from "./Onboarding/Pages/Dashboard";
import HowItWorks from "./Onboarding/Pages/HowItWorks";
import OtpPage from "./Onboarding/Pages/OtpPage";
import Permissions from "./Onboarding/Pages/Permissions";
import PhoneNumber from "./Onboarding/Pages/PhoneNumber";
import ProfilePreview from "./Onboarding/Pages/ProfilePreview";
import ProfileSettings from "./Onboarding/Pages/ProfileSettings";
import Transactions from "./Onboarding/Pages/Transactions";
import Card from "./Onboarding/Pages/Card";
import Wallet from "./Onboarding/Pages/Wallet";
import WalletDetails from "./Onboarding/Pages/WalletDetails";
import Shop from "./Onboarding/Pages/Shop";
import SelectSeat from "./Onboarding/Pages/SelectSeat";
import PaymentOptions from "./Onboarding/Pages/PaymentOptions";
import SplitPay from "./Onboarding/Pages/SplitPay";
import Payment from "./Onboarding/Pages/Payment";
import PaymentSuccess from "./Onboarding/Pages/PaymentSuccess";
import RouteGuard from "./Components/RouteGuard";
import Splash from "./Onboarding/Pages/Splash";

function App() {
  const routes = useRoutes([
    { path: "/", element: <Splash /> },
    { path: "*", element: <PhoneNumber /> },
    { path: "/home", element: <PhoneNumber /> },
    { path: "/verifyotp", element: <OtpPage /> },
    {
      element: <RouteGuard />,
      children: [
        { path: "/customerinfo", element: <CustomerInfo /> },
        { path: "/permission", element: <Permissions /> },
        { path: "/profilepreview", element: <ProfilePreview /> },
        { path: "/dashboard", element: <Dashboard /> },
        { path: "/card", element: <Card /> },
        { path: "/transactions", element: <Transactions /> },
        { path: "/how-it-works", element: <HowItWorks /> },
        { path: "/profile", element: <ProfileSettings /> },
        { path: "/wallet", element: <Wallet /> },
        { path: "/wallet-details/:loan_id", element: <WalletDetails /> },
        { path: "/shop", element: <Shop /> },
        { path: "/select", element: <SelectSeat /> },
        { path: "/pay-options", element: <PaymentOptions /> },
        { path: "/pay-method", element: <SplitPay /> },
        { path: "/pay", element: <Payment /> },
        { path: "/pay-success", element: <PaymentSuccess /> },
      ],
    },
  ]);
  return routes;
}

export default App;
