import React from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

const Navbar = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const navbar = [
    {
      id: 1,
      name: "Home",
      imgUrl: "/home-icon.svg",
      route: "/dashboard",
      paths: ['/dashboard']
    },
    {
      id: 2,
      name: "Shop",
      imgUrl: "/shop-icon.svg",
      route: "/shop",
      paths:['/shop', '/select', '/pay-options','/pay-method', '/pay']
    },
    {
      id: 3,
      name: "Transactions",
      imgUrl: "/transactions-icon.svg",
      route: "/transactions",
      paths: ['/transactions']
    },
    {
      id: 4,
      name: "Wallet",
      imgUrl: "/wallet.svg",
      route: "/wallet",
      paths: ['/wallet']
    },
    {
      id: 5,
      name: "Profile",
      imgUrl: "/profile-icon.svg",
      route: "/profile",
      paths: ['/profile']
    },
  ];
  return (
    <>
      <div className="mainmenu">
        {navbar.map((nav) => {
          return (
            <div key={nav.id}
              className={`menublock ${
               nav.paths.includes(pathname) ? "menuActive" : ""
              }`}
              onClick={() => navigate(nav.route)}
            >
              <div className="menuIcon">
                <img src={nav.imgUrl} />
              </div>
              <div className="menutext">{nav.name}</div>
            </div>
          );
        })}
      </div>
    </>
  );
};
export default Navbar;
