import React from "react";
import Navbar from "../../Components/Navbar/Navbar";

const HowItWorks = () => {
  return (
    <>
      <div className="walletpage">
        <div className="headersection">
          <div>
            {" "}
            <img src="/back-icon.svg" />{" "}
          </div>
          <div>How you will pay</div>
          <div></div>
        </div>

        <div className="contentSection">
          <div className="whiteBox2 flex_column">
            <div className="fontWeight700">Split in 4</div>
            <div className="fs12px">
              {" "}
              Split your purchase into equal monthly installments
            </div>
          </div>

          <div className="splitsection">
            <div className="splitblock">
              <div className="splitblockIcon">
                <img src="/first25per.svg" />
              </div>
              <div className="fontWeight600">25%</div>
              <div className="graytext2">Today</div>
            </div>
            <div className="splitblock">
              <div className="splitblockIcon">
                <img src="/second25per.svg" />
              </div>
              <div className="fontWeight600">25%</div>
              <div className="graytext2">1st Month</div>
            </div>
            <div className="splitblock">
              <div className="splitblockIcon">
                <img src="/third25per.svg" />
              </div>
              <div className="fontWeight600">25%</div>
              <div className="graytext2">2nd Month</div>
            </div>
            <div className="splitblock">
              <div className="splitblockIcon">
                <img src="/fourth25per.svg" />
              </div>
              <div className="fontWeight600">25%</div>
              <div className="graytext2">3rd Month</div>
            </div>
          </div>

          <div className="howitwork">
            <div className="maintitle">Lorem Ipsum :</div>
            <div className="listsection">
              <div className="innercircle fs10px">25%</div>
              <div>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry.
              </div>
            </div>
            <div className="listsection">
              <div className="innercircle fs10px">50%</div>
              <div>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry.
              </div>
            </div>
            <div className="listsection">
              <div className="innercircle fs10px">75%</div>
              <div>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry.
              </div>
            </div>
            <div className="listsection">
              <div className="innercircle fs10px">100%</div>
              <div>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry.
              </div>
            </div>
          </div>

          <button className="blueButton mt100 mt30">Create Card</button>
        </div>
      </div>
      <Navbar />
    </>
  );
};

export default HowItWorks;
