const axios = require('axios')

const createHandler = (method) => {
  return async function (url, body, headers = {}) {
    const config = {
      method,
      url,
      data: body,
      headers: {
        'Cache-Control': 'no-cache',
        'Content-Type': 'application/json',
        ...headers,
      },
    }
    try {
      const response = await axios(config)
      return response.data
    } catch (error) {
      // throw new Error(JSON.stringify(error.response));
      return error.response
    }
  }
}

const httpHandler = {
  get: createHandler('get'),
  post: createHandler('post'),
  put: createHandler('put'),
  patch: createHandler('patch'),
}

export default httpHandler
