import { useFormik } from "formik";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { AuthPost, Post } from "../../common_var/httpService";
import Navbar from "../../Components/Navbar/Navbar";

const Payment = () => {
  const navigate = useNavigate();
  const merchantId = "f379d1b4-0556-467d-9052-86c67fae1b99";
  const user_id = sessionStorage.getItem("userId");
  const amount = sessionStorage.getItem("principal");
  const pay_mode = sessionStorage.getItem("pay_mode");
  const [insuffBalancePopup, setInsuffBalancePopup] = useState({
    isOpen: false,
    message: "",
  });

  const { handleBlur, handleSubmit, values, errors, touched } = useFormik({
    initialValues: {
      payBy: "",
    },
    validate: (values) => {
      const errors = {};

      if (!values.payBy) {
        errors.payBy = "Required";
      }

      return errors;
    },
    onSubmit: async (values) => {
      try {
        const body = {
          user_id: user_id,
          purchase_amount: +amount,
          payment_mode: pay_mode,
          merchant_id: merchantId,
          payby: values.payBy,
        };
        const resp = await AuthPost(
          "transaction/purchaseproduct",
          body,
          "borrower"
        );
        if (resp.statusCode == 200) {
          navigate("/pay-success");
        } else if (resp.statusCode == 400) {
          setInsuffBalancePopup({
            ...insuffBalancePopup,
            isOpen: true,
            message: resp.message,
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
  });

  const handleChange = (e) => {
    values.payBy = e.target.value;
  };
  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className="walletpage">
          <div className="headersection">
            <div onClick={() => window.history.back()}>
              <img src="/back-icon.svg" />
            </div>
            <div>Payments</div>
            <div></div>
          </div>
          <div className="contentSection">
            <div className="innertitle">Payments plan</div>
            <div className="whiteBox3 flex_column">
              <div className="innertitle">Payments plan</div>

              <div
                className="whiteBox3"
                style={
                  errors.payBy && touched.payBy && { border: "1px solid red" }
                }
              >
                <label htmlFor="theepay-wallet" style={{ width: "100%" }}>
                  <div className="displayFlex ">
                    <div className=" ">{/* <img src="/tick.png" />   */}</div>
                    <div>
                      <div className="theepaytext">
                        <input
                          type="radio"
                          name="payBy"
                          id="theepay-wallet"
                          className="mr20"
                          value="Wallet"
                          onChange={(e) => handleChange(e)}
                          onBlur={handleBlur}
                        />
                        Theepay Wallet
                      </div>
                      <div className="theepaygraytext">Available Balance</div>
                    </div>
                  </div>
                </label>

                <div>
                  {" "}
                  <img src="/wallet.svg" />{" "}
                </div>
              </div>

              <div
                className="whiteBox3"
                style={{ backgroundColor: "lightgray" }}
              >
                <label htmlFor="theepay-card" style={{ width: "100%" }}>
                  <div className="displayFlex ">
                    <div className=" ">{/* <img src="/tick.png" />   */}</div>
                    <div>
                      <div className="theepaytext">
                        <input
                          disabled
                          type="radio"
                          name="payBy"
                          id="theepay-card"
                          className="mr20"
                          value="Card"
                          onChange={(e) => handleChange(e)}
                          onBlur={handleBlur}
                        />
                        Theepay Card
                      </div>
                      <div className="theepaygraytext">
                        Card XXXX XXXX XXXX XXXX
                      </div>
                      <div className="pt20">Available Balance 0.00</div>
                    </div>
                  </div>
                </label>

                <div>
                  {" "}
                  <img src="/mastercard.png" />{" "}
                </div>
              </div>
            </div>
            <div className="whiteBox3">
              <div>
                <div className="totalamouttxt">Total Amount</div>
                <div className="totalvaluetxt">AED 1386.56</div>
              </div>

              <button type="submit" className="blueButton winherit">
                Pay Now
              </button>
            </div>
          </div>
        </div>
      </form>

      {insuffBalancePopup.isOpen && (
        <div className="insufficientpopup">
          <div className="insufficientinnerpopup">
            <img src="/info-icon.svg" />

            <div className="insufficientinnerpopuptext">
              {insuffBalancePopup.message}
              <br />
              Purchase Failed!
            </div>

            <button
              onClick={() => navigate("/dashboard")}
              type="button"
              className="checkbalancebutton"
            >
              {" "}
              {insuffBalancePopup.message == "Insufficient balance"
                ? "Check Balance"
                : "Contact Admin"}
            </button>
          </div>
        </div>
      )}

      {/* <div>Insufficient balance</div> */}

      <Navbar />
    </>
  );
};

export default Payment;
