import httpHandler from "./httpHandler";

export const geturl = (url, key) => {
  if (key == "admin") {
    return `${process.env.REACT_APP_ADMIN_API_URL}/${url}`;
  } else if (key == "borrower") {
    return `${process.env.REACT_APP_BORROWER_API_URL}/${url}`;
  } else if (key == "dealer") {
    return `${process.env.REACT_APP_DEALER_API_URL}/${url}`;
  } else {
    return `${process.env.REACT_APP_SALES_API_URL}/${url}`;
  }
};

export const AuthPost = async (url, body, key) => {
  let token;
  if (key == "admin") {
    token = sessionStorage.getItem("admin_token");
  } else if (key == "borrower") {
    token = sessionStorage.getItem("borrower_token");
  } else if (key == "dealer") {
    token = sessionStorage.getItem("dealer_token");
  }
  let optionalHeaders = {
    Authorization: token ? `Bearer ${token}` : "",
  };
  let activeUrl = geturl(url, key);
  let data = await httpHandler.post(activeUrl, body, optionalHeaders);
  return data;
};

export const AuthGet = async (url, key) => {
  let token;
  if (key == "admin") {
    token = sessionStorage.getItem("admin_token");
  } else if (key == "borrower") {
    token = sessionStorage.getItem("borrower_token");
  } else if (key == "dealer") {
    token = sessionStorage.getItem("dealer_token");
  }
  let UserId = sessionStorage.getItem("userData");
  let optionalHeaders = {
    Authorization: token ? `Bearer ${token}` : "",
    UserId: UserId ? UserId : "",
  };
  let activeUrl = geturl(url, key);
  let data = await httpHandler.get(activeUrl, "", optionalHeaders);
  return data;
};

export const Get = async (url, key) => {
  let activeUrl = geturl(url, key);
  let data = await httpHandler.get(activeUrl, "", {});
  return data;
};

export const Post = async (url, body, key) => {
  let activeUrl = geturl(url, key);
  let data = await httpHandler.post(activeUrl, body, {});
  return data;
};

export const Put = async (url, body, key) => {
  let activeUrl = geturl(url, key);
  let data = await httpHandler.put(activeUrl, body, {});
  return data;
};

export const AuthPut = async (url, body, key) => {
  let token;
  if (key == "admin") {
    token = sessionStorage.getItem("admin_token");
  } else if (key == "borrower") {
    token = sessionStorage.getItem("borrower_token");
  } else if (key == "dealer") {
    token = sessionStorage.getItem("dealer_token");
  }
  let UserId = sessionStorage.getItem("UserId");
  let optionalHeaders = {
    Authorization: token ? `Bearer ${token}` : "",
  };
  let activeUrl = geturl(url, key);
  let data = await httpHandler.put(activeUrl, body, optionalHeaders);
  return data;
};

export const AuthFile = async (url, body, key, method = "post") => {
  let token;
  if (key == "admin") {
    token = sessionStorage.getItem("admin_token");
  } else if (key == "borrower") {
    token = sessionStorage.getItem("borrower_token");
  } else if (key == "dealer") {
    token = sessionStorage.getItem("dealer_token");
  }

  let activeUrl = geturl(url, key);
  if (method === "post") {
    let data = await httpHandler.post(activeUrl, body, {
      "content-type": "multipart/form-data",
      Authorization: token ? `Bearer ${token}` : "",
    });
    return data;
  } else if (method === "patch") {
    let data = await httpHandler.patch(activeUrl, body, {
      headers: {
        "content-type": "multipart/form-data",
      },
    });
    return data;
  }
};

export const AuthDownloadblob = async (url, key) => {
  let token;
  if (key == "admin") {
    token = sessionStorage.getItem("admin_token");
  } else if (key == "borrower") {
    token = sessionStorage.getItem("borrower_token");
  } else if (key == "dealer") {
    token = sessionStorage.getItem("dealer_token");
  }

  let optionalHeaders = {
    Authorization: token ? `Bearer ${token}` : "",
    responseType: "blob",
  };

  let activeUrl = geturl(url, key);
  let data = await httpHandler.get(activeUrl, "", optionalHeaders);
  return data;
};
