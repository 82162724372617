import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const Permissions = () => {
  const navigate = useNavigate();
  useEffect(() => {
    window.history.pushState(null, null, window.location.href);
    window.onpopstate = function () {
      window.history.go(1);
    };
  },[])
  return (
    <div className="innermainsection height100vh">
      <div className="displayFlex AlignItemCenter justifyContent_center flex_column">
        <div className="titlename ">Grant us some permissions</div>
        <div className="pb40 brandsubtitle">
          Your information is safe with us
        </div>
      </div>

      <div className="pb40">
        <p className="fontWeight700 fs16px">SMS Logs</p>
        <p>
          Lorem Ipsum is simply dummy text of the printing and typesetting
          industry. Lorem Ipsum has been the industry's standard dummy text ever
          since the 1500s, when an unknown printer took a galley of type and
          scrambled it to make a type specimen book. It has survived not only
          five centuries, but also the leap into electronic typesetting,
          remaining essentially unchanged. It was popularised in the 1960s with
          the release of Letraset sheets containing Lorem Ipsum passages, and
          more recently with desktop
        </p>
      </div>
      <div className="pb40">
        <p className="fontWeight700 fs16px">Contacts</p>
        <p>
          Lorem Ipsum is simply dummy text of the printing and typesetting
          industry. Lorem Ipsum has been the industry's standard dummy text ever
          since the 1500s, when an unknown printer took a galley of type and
          scrambled it to make a type specimen book. It has survived not only
          five centuries, but also the leap into electronic typesetting,
          remaining essentially unchanged. It was popularised in the 1960s with
          the release of Letraset sheets containing Lorem Ipsum passages, and
          more recently with desktop
        </p>
      </div>

      <button onClick={() => navigate('/profilepreview')} type="submit" className="blueButton mt100 mt30">
        Allow access
      </button>
    </div>
  );
};

export default Permissions;
