import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AuthGet, AuthPost } from "../../common_var/httpService";
import Navbar from "../../Components/Navbar/Navbar";

const WalletDetails = () => {
  const { loan_id } = useParams();
  const [transDetails, setTransDetails] = useState([]);
  const navigate = useNavigate()

  const getTransDetails = async () => {
    try {
      const resp = await AuthGet(
        `transaction/get_purchaseDetail/${loan_id}`,
        "borrower"
      );
      if (resp.statusCode === 200) {
        setTransDetails(resp.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const payNow = async (id, status) => {
    if (status == "UNPAID") {
      const resp = await AuthPost(`transaction/pay/${id}`, {}, "borrower");
      if (resp.statusCode === 200) {
        getTransDetails();
      }
    }
  };

  useEffect(() => {
    getTransDetails();
  }, []);

  return (
    <div>
      <div>
        <div className="walletdetailsheader">
          <div onClick={() => navigate('/wallet')}>
            {" "}
            <img src="/back-icon.svg" />{" "}
          </div>
          <div className="mr15px">Wallet</div>
          <div></div>
        </div>
        <div className="plastic_cloudbg">
          <div className=" displayFlex justifyContent_center ">
            <div className="displayFlex flex_column justifyContent_center AlignItemCenter">
              <div className="walletdetails_logoblock">
                <img width={"100%"} height={"100%"} src="/movie.png" alt="img" />
              </div>
              <div className="textAlign_center">
                <div className="walletdetailstitle">
                  {transDetails[0]?.product_name}
                </div>
                <p className="walletdetailssubtitle">#254-5615</p>
              </div>
            </div>
          </div>

          <div className=" ">
            <div className="p16 displayFlex spacebetween">
              <div className="orderdate_card">
                <div className="orderdate_cardtitle">Order date</div>
                <p>{transDetails[0]?.createdAt}</p>
              </div>
              <div className="orderdate_card">
                <div className="orderdate_cardtitle">Purchase Method</div>
                <p>Online Order</p>
              </div>
            </div>
          </div>
        </div>
        <div className="plastic_cloudbg">
          <div className="paymentschedule_section">
            <div className="paymentschedule_block">
              <div className="paymentschedule_title">Payment Schedule</div>
            </div>
            {/* "displayFlex spacebetween paymentschedule_card AlignItemCenter" */}
            <div>
              {transDetails?.map((tran, i,arr) => (
                <div className= { i > 0 ?  (arr[i-1].status_flag == 'UNPAID' ? 'displayFlex spacebetween paymentschedule_card AlignItemCenter opactiyddull': "displayFlex spacebetween paymentschedule_card AlignItemCenter")   : (arr[i].status_flag == "displayFlex spacebetween paymentschedule_card AlignItemCenter"? false: "displayFlex spacebetween paymentschedule_card AlignItemCenter")  }    >
                  <div className="schedule_year">{tran.scheduledate}</div>
                  <div className=" fontWeight600 fs12px">
                    AED {tran?.amount?.trim()}
                  </div>
                  <div className= {tran.status_flag == "UNPAID" ? "paynow_btn" : "paid_btn"}>
                 
                    <button  disabled= { i > 0 ?  (arr[i-1].status_flag == 'UNPAID' ? true: false)   : (arr[i].status_flag == 'UNPAID' ? false: null)  }      onClick={() => payNow(tran.id, tran.status_flag)}>
                    
                      {tran.status_flag == "UNPAID" ? "Pay Now" : "Paid"} 
                    </button>
                  </div>
                </div>
              ))}
              {/* <div className="displayFlex spacebetween paymentschedule_card AlignItemCenter">
                <div className="schedule_year">Total Amount</div>
                <div className=" fontWeight600 fs14px text_secondary">
                  AED 2,000
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
      <Navbar />
    </div>
  );
};

export default WalletDetails;
