import { Center } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AuthGet } from "../../common_var/httpService";

const ProfileSettings = () => {
  const [userProfile, setUserProfile] = useState({});
  let navigate = useNavigate();

  const getUserProfile = async () => {
    let user_id = sessionStorage.getItem("userId");
    let res = await AuthGet(`customer/userprofile/${user_id}`, "borrower");
    if (res.statusCode === 200) {
      setUserProfile(res.data[0]);
    }
  };

  const logout = () => {
    sessionStorage.clear();
    navigate("/");
  };

  useEffect(() => {
    getUserProfile();
  }, []);

  const handleClick = () => {
    userProfile?.customer_status == "Verified"
      ? navigate("/dashboard")
      : navigate("/profilepreview");
  };
  return (
    <div>
      <div className="profileheader">
        <div onClick={handleClick}>
          <img src="/profile-back.svg" />
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <div>
            <div
              style={{
                width: 120,
                height: 120,
                borderRadius: "50%",
                backgroundColor: "whitesmoke",
                color: "black",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontSize: "70px",
                texttransform: "capitalize",
                fontWeight: "bold",

              }}
            >
              {userProfile?.firstname ? userProfile?.firstname[0] : ""}
            </div>
          </div>
          <div className="displayFlex profilename  pt10">
            <h2>
              {userProfile.firstname
                ? userProfile.firstname
                : "" + " " + userProfile.middle_initial
                  ? userProfile.middle_initial
                  : "" + " " + userProfile.lastname
                    ? userProfile.lastname
                    : ""}
            </h2>
            <div>
              <img src="/profile-edit.svg" alt="img" />
            </div>
          </div>
        </div>
        <div></div>
      </div>

      <div className="displayFlex AlignItemCenter spacebetween profileverifyblock">
        <div>
          <div className="profileverifytitle">
            Complete identity verification
          </div>
          <div className="profileverifysubtitle">
            Get pre-approved for upcoming features
          </div>
        </div>

        <div>
          <img src="/profile-verificationicon.svg" alt="img" />
        </div>
      </div>

      <div>
        <div className="displayFlex AlignItemCenter option_card">
          <div>
            <img src="/profile-phone.svg" alt="img" />
          </div>
          <div className="optioncardrightsection">
            <div className="displayFlex AlignItemCenter ">
              <div className="optiontitle">Phone Number</div>{" "}
              <div>
                <img src="/profile-verifytick.svg" alt="img" />
              </div>
            </div>
            <p className="optionsubtitle">{userProfile?.phone}</p>
          </div>
        </div>
        <div className="displayFlex AlignItemCenter option_card">
          <div>
            <img src="/profile-dapi.svg" alt="img" />
          </div>
          <div className="optioncardrightsection">
            <div className="displayFlex AlignItemCenter ">
              <div className="optiontitle">Bank Verification</div>{" "}
              {userProfile?.bank_verified ? (
                <div>
                  <img src="/profile-verifytick.svg" alt="img" />
                </div>
              ) : (
                <>
                  <br />
                  <p className="optionsubtitle">ID is not initiated.</p>
                </>
              )}
            </div>
          </div>
        </div>
        <div className="displayFlex AlignItemCenter option_card spacebetween">
          <div className="displayFlex AlignItemCenter">
            <div>
              <img src="/profile-help.svg" alt="img" />
            </div>
            <div className="optioncardrightsection">
              <div className="optiontitle">Help & Support</div>
            </div>
          </div>
          <div>
            <img src="/profile-rightarrow.svg" alt="img" />
          </div>
        </div>
        <div className="displayFlex AlignItemCenter option_card spacebetween">
          <div className="displayFlex AlignItemCenter">
            <div>
              <img src="/profile-privacy.svg" alt="img" />
            </div>
            <div className="optioncardrightsection">
              <div className="optiontitle">Privacy Policy</div>
            </div>
          </div>
          <div>
            <img src="/profile-rightarrow.svg" alt="img" />
          </div>
        </div>
        <div className="displayFlex AlignItemCenter option_card spacebetween">
          <div className="displayFlex AlignItemCenter">
            <div>
              <img src="/profile-termsandcondition.svg" alt="img" />
            </div>
            <div className="optioncardrightsection">
              <div className="optiontitle">Terms and Conditions</div>
            </div>
          </div>
          <div>
            <img src="/profile-rightarrow.svg" alt="img" />
          </div>
        </div>

        <div className="displayFlex AlignItemCenter option_card spacebetween">
          <div className="displayFlex AlignItemCenter">
            <div>
              <img src="/profile-logout.svg" alt="img" />
            </div>
            <div className="optioncardrightsection">
              <div className="optiontitle" onClick={logout}>
                Log Out
              </div>
            </div>
          </div>
          <div>
            <img src="/profile-rightarrow.svg" alt="img" />
          </div>
        </div>
        <div className="greylogo">
          <img src="/theepaylogo-grey.svg" alt="img" />
        </div>
      </div>
    </div>
  );
};

export default ProfileSettings;
